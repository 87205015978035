import React from "react";
import { Navbar, Nav, Image } from "react-bootstrap";
import { BiBell, BiChat } from "react-icons/bi";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGear } from "@fortawesome/free-solid-svg-icons";
import default_img from "assets/images/account_circle.svg";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { imagePreviewFormik } from "helpers";
import header_logo from "assets/images/header_logo.png";
const PrivateHeader = ({ toggleSidebar, getSettingData, t }) => {
  const user = useSelector((state) => state.user);
  const isGoogleImage = user.user.profile_image.includes(
    "googleusercontent.com"
  );
  return (
    <Navbar
      bg="dark"
      variant="dark"
      className="justify-content-between pvt-header ps-3"
    >
      <Navbar.Brand className="private-header">
        <Link to={"/"} className="text-light text-decoration-none">
          <img
            src={
              getSettingData?.data.home_page_general_home_page_logo ||
              header_logo
            }
            alt="Header logo"
            className="home-logo"
          />
        </Link>
      </Navbar.Brand>
      <Nav className="d-flex align-items-center gap-2 me-3">
        <Nav.Link href="#chat">
          <BiChat size={28} color="#fff" />
        </Nav.Link>
        <Nav.Link href="#notifications">
          <BiBell size={28} color="#fff" />
        </Nav.Link>
        <Link to={"/edit-profile"}>
          <Image
            src={
              user.user.profile_image.split("/").pop() !== "default.jpeg"
                ? imagePreviewFormik(user.user.profile_image)
                : default_img
            }
            roundedCircle
            width="40"
            height="40"
          />
        </Link>
        <Nav.Link onClick={toggleSidebar}>
          <FontAwesomeIcon icon={faGear} className="text-light fs-4" />
        </Nav.Link>
      </Nav>
    </Navbar>
  );
};

// Define PropTypes to ensure toggleSidebar is passed
PrivateHeader.propTypes = {
  toggleSidebar: PropTypes.func.isRequired,
};

export default PrivateHeader;

import React, { useState } from "react";
import { Accordion, Nav } from "react-bootstrap";
import PropTypes from "prop-types";
import "assets/scss/section/_private_sidebar.scss";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { logoutSuccess } from "store/features/authSlice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import user_icon from "assets/images/account_circle.svg";
import path from "assets/images/Path.svg";
import add_card from "assets/images/add_card.svg";
import description from "assets/images/description.svg";
import favorite from "assets/images/favorite.svg";
import history from "assets/images/history.svg";
import move_item from "assets/images/move_item.svg";
import receipt_long from "assets/images/receipt_long.svg";
import reviews from "assets/images/reviews.svg";

const PrivateSidebar = ({ isOpen, toggleSidebar, getSettingData, t }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleLogout = () => {
    dispatch(logoutSuccess());
    navigate("/login");
  };
  const [activeKey, setActiveKey] = useState(null);

  const handleToggle = (eventKey) => {
    setActiveKey(activeKey === eventKey ? null : eventKey);
  };
  const handleCanvasButtonClick = () => {
    // // Get the collection of elements with the class name "offcanvas"
    const elements = document.getElementsByClassName("offcanvas");

    // Check if there are any elements with this class
    if (elements.length > 0) {
      // Access the first element in the collection
      const element = elements[0];

      // Remove the "show" class from the first element
      element.classList.remove("show");
      const toggle = document.getElementsByClassName("left-toggle");
      toggle[0].click();
      // Log the element to the console
      console.log("Element:", element);
    } else {
      console.log("No elements found with the class 'offcanvas'.");
    }
  };
  return (
    <div
      className={`pvt-sidebar  vh-100 text-white p-3 ${
        isOpen ? "pvt-sidebar-open" : "pvt-sidebar-closed"
      }`}
    >
      <button
        className="pvt-sidebar-close-btn"
        onClick={toggleSidebar}
        aria-label="Close sidebar"
      >
        &times;
      </button>

      <Nav className="flex-column mt-4 sidebar-content">
        <Link
          to={"/edit-profile"}
          className="text-white text-decoration-none ms-md-3 my-2"
          onClick={toggleSidebar}
        >
          <img src={user_icon} alt="" /> My Profile
        </Link>
        <Accordion activeKey={activeKey} onSelect={handleToggle}>
          <Accordion.Item eventKey="0">
            <Accordion.Header
              className={activeKey === "0" ? "custom-acc-header " : "my-2"}
            >
              {/* Arrow indicator */}
              {activeKey === "0" ? <FaChevronUp /> : <FaChevronDown />}
              <img src={path} alt="" className="me-3 ms-1" />
              Ads Management
            </Accordion.Header>
            <Accordion.Body>
              <Nav.Link
                href="#create-ads"
                onClick={toggleSidebar}
                className="text-white"
              >
                Create Ads
              </Nav.Link>
              <Nav.Link
                href="#ads-list"
                onClick={toggleSidebar}
                className="text-white"
              >
                Ads List
              </Nav.Link>
              <Nav.Link
                href="#completed-ads"
                onClick={toggleSidebar}
                className="text-white"
              >
                Completed Ads
              </Nav.Link>
              <Nav.Link
                href="#cancelled-ads"
                onClick={toggleSidebar}
                className="text-white"
              >
                Cancelled Ads
              </Nav.Link>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
        <Nav.Link
          href="#favourite"
          onClick={toggleSidebar}
          className="text-white my-2"
        >
          <img src={favorite} alt="" /> Favourite
        </Nav.Link>
        <Link
          to={"/manage-card"}
          className="text-white my-2 text-decoration-none ms-md-3"
          onClick={toggleSidebar}
        >
          <img src={add_card} alt="" /> Manage Card
        </Link>
        <Nav.Link
          href="#addon-history"
          onClick={toggleSidebar}
          className="text-white my-2"
        >
          <img src={history} alt="" /> Ad-on History
        </Nav.Link>
        <Nav.Link
          href="#payment-history"
          onClick={toggleSidebar}
          className="text-white my-2"
        >
          <img src={receipt_long} alt="" /> Payment History
        </Nav.Link>
        <Nav.Link
          href="#reviews"
          onClick={toggleSidebar}
          className="text-white my-2"
        >
          <img src={reviews} alt="" /> Reviews
        </Nav.Link>
        <Nav.Link
          onClick={() => {
            handleLogout();
            toggleSidebar();
          }}
          className="text-white my-2"
        >
          <img src={move_item} alt="" /> Logout
        </Nav.Link>
      </Nav>
    </div>
  );
};

PrivateSidebar.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggleSidebar: PropTypes.func.isRequired, // Add toggleSidebar function prop
};

export default PrivateSidebar;

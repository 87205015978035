import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { Routes, Route, useLocation } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import PublicRoute from "./PublicRoute";
import Main from "pages/Homepage/Main";
import "locales/i18n";
import { useGetSettingData } from "hooks";
import About from "pages/About.js/About";
import ContactUs from "pages/Contact/ContactUs";
import FaqPage from "pages/FaqPage/FaqPage";
import { PageNotFound } from "common/components/PageNotFound";
import Privacy from "pages/CmsPage/Privacy";
import TermCondition from "pages/CmsPage/TermCondition";
import LoginPage from "pages/Auth/LoginPage";
import Registration from "pages/Auth/Registration";
import OtpVarification from "pages/Auth/OtpVarification";
import CmsRoutes from "pages/CmsPage/CmsRoutes";
import ForgotPasswordPage from "pages/Auth/ForgotPassword/ForgotPasswordPage";
import ResetPasswordPage from "pages/Auth/ResetPassword/ResetPasswordPage";
import PrivateRoute from "./PrivateRoute";
import Dashboard from "pages/Dashboard/Dashboard";
import EditProfilePage from "pages/Account/EditProfile/EditProfilePage";
import ChangePassword from "pages/Account/ChangePassword/ChangePassword";
import DeactivateAccount from "pages/Account/DeactivateAccount/DeactivateAccount";
import ManageCard from "pages/Payment/ManageCard";

const PagesRoutes = ({ t }) => {
  const location = useLocation();

  // Determine route name based on location.pathname
  const route_name =
    location.pathname === "/" ||
    location.pathname.startsWith("/page/") ||
    location.pathname.startsWith("/company")
      ? "home"
      : location.pathname.substr(1);

  const { data: getSettingData } = useGetSettingData(
    route_name,
    () => {},
    (error) => {
      if (error.data.redirect == true) {
        console.log("Error occurred: ", error.message);
      }
    }
  );

  var pageTitle = t("front_page.default_page_title_label");
  var seoTitle = getSettingData?.data?.home_setting_seo_title;
  var seoDescription = getSettingData?.data?.home_setting_seo_description;
  if (location.pathname == "/faq") {
    pageTitle = t("front_page.faq_page_title_label");
    seoTitle = getSettingData?.data?.faq_setting_seo_title;
    seoDescription = getSettingData?.data?.faq_setting_seo_description;
  } else if (location.pathname == "/contact") {
    pageTitle = t("front_page.contact_us_page_title_label");
    seoTitle = getSettingData?.data?.contact_setting_seo_title;
    seoDescription = getSettingData?.data?.contact_setting_seo_description;
  } else if (location.pathname == "/about") {
    pageTitle = t("front_page.about_page_title_label");
    seoTitle = getSettingData?.data?.about_setting_seo_title;
    seoDescription = getSettingData?.data?.about_setting_seo_description;
  } else if (location.pathname == "/privacy-policy") {
    pageTitle = t("front_page.privacy_policy_title_label");
  } else if (location.pathname == "/terms-conditions") {
    pageTitle = t("front_page.terms_conditions_title_label");
  } else if (location.pathname == "/register") {
    pageTitle = t("front_page.register_title_label");
  } else if (location.pathname == "/otp-verification") {
    pageTitle = t("front_page.otp_verification_title_label");
  } else if (location.pathname == "/login") {
    pageTitle = t("front_page.login_title_label");
  } else if (location.pathname == "/forgot-password") {
    pageTitle = t("front_page.forgot_password_title_label");
  } else if (location.pathname == "/reset-password") {
    pageTitle = t("front_page.reset_password_title_label");
  } else if (location.pathname == "/dashboard") {
    pageTitle = "Collegely | Dashboard";
  } else if (location.pathname == "/edit-profile") {
    pageTitle = "Collegely | Edit Profile";
  } else if (location.pathname == "/change-password") {
    pageTitle = "Collegely | Change Password";
  } else if (location.pathname == "/deactivate-account") {
    pageTitle = "Collegely | Deactivate Account";
  } else if (location.pathname == "/manage-card") {
    pageTitle = "Collegely | Manage Card";
  }
  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="title" content={seoTitle} />
        <meta name="description" content={seoDescription} />
        <meta property="og:title" content={seoTitle} />
        <meta property="og:description" content={seoDescription} />
        <link
          rel="icon"
          type="image/x-icon"
          href={getSettingData?.data.home_page_general_favicon_logo}
        />
      </Helmet>
      <Routes>
        <Route element={<PublicRoute getSettingData={getSettingData} t={t} />}>
          <Route
            exact
            path="/login"
            element={<LoginPage getSettingData={getSettingData} t={t} />}
          />
          <Route
            exact
            path="/register"
            element={<Registration getSettingData={getSettingData} t={t} />}
          />
          <Route
            exact
            path="/forgot-password"
            element={
              <ForgotPasswordPage getSettingData={getSettingData} t={t} />
            }
          />
          <Route
            exact
            path="/reset-password"
            element={
              <ResetPasswordPage getSettingData={getSettingData} t={t} />
            }
          />
          <Route
            exact
            path="/otp-verification"
            element={<OtpVarification getSettingData={getSettingData} t={t} />}
          />
          <Route
            exact
            path="/"
            element={<Main getSettingData={getSettingData} t={t} />}
          />
          <Route
            exact
            path="/about"
            element={<About getSettingData={getSettingData} t={t} />}
          />
          <Route
            exact
            path="/contact"
            element={<ContactUs getSettingData={getSettingData} t={t} />}
          />
          <Route
            exact
            path="/faq"
            element={<FaqPage getSettingData={getSettingData} t={t} />}
          />
          <Route path="/page/*" element={<CmsRoutes t={t} />} />
        </Route>
        <Route element={<PrivateRoute getSettingData={getSettingData} t={t} />}>
          <Route
            path="/dashboard"
            element={<Dashboard getSettingData={getSettingData} t={t} />}
          />
          <Route
            path="/edit-profile"
            element={<EditProfilePage getSettingData={getSettingData} t={t} />}
          />
          <Route
            path="/change-password"
            element={<ChangePassword getSettingData={getSettingData} t={t} />}
          />
          <Route
            path="/deactivate-account"
            element={
              <DeactivateAccount getSettingData={getSettingData} t={t} />
            }
          />
          <Route
            path="/manage-card"
            element={<ManageCard getSettingData={getSettingData} t={t} />}
          />
        </Route>
        <Route
          path="*"
          element={<PageNotFound getSettingData={getSettingData} t={t} />}
        />
      </Routes>
    </>
  );
};

PagesRoutes.propTypes = {
  t: PropTypes.func,
};

export default PagesRoutes;

import React, { useState } from "react";
import { Accordion, Button, Card, Col, Row } from "react-bootstrap";
import { faPlus, faMinus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import flag from "assets/images/usa-flag.png";

const CustomToggle = ({ children, eventKey, onClick, isExpanded }) => {
  return (
    <Row className="text-light d-flex justify-content-center align-items-center">
      <Col
        md={9}
        className="text-center toggle-header"
        onClick={onClick}
        style={{ cursor: "pointer" }}
      >
        {children}
      </Col>
      <Col
        className="accordion-btn"
        onClick={onClick}
        style={{ cursor: "pointer" }}
      >
        <FontAwesomeIcon
          icon={isExpanded ? faMinus : faPlus}
          className="ms-2 toggle-header"
        />
      </Col>
    </Row>
  );
};

const RightSidebar = () => {
  const [expandedKey, setExpandedKey] = useState(null);

  const handleToggle = (key) => {
    setExpandedKey(expandedKey === key ? null : key);
  };

  const handleCanvasButtonClick = () => {
    const elements = document.getElementsByClassName("offcanvas");
    if (elements.length > 0) {
      const element = elements[0];
      element.classList.remove("show");
      const toggle = document.getElementsByClassName("right-toggle");
      toggle[0].click();
    } else {
      console.log("No elements found with the class 'offcanvas'.");
    }
  };

  return (
    <div className="right-sidebar bg-dark text-white p-3 position-relative sticky-right-sidebar">
      <div>
        <button
          type="button"
          className="btn-close canvas-btn"
          aria-label="Close"
          onClick={handleCanvasButtonClick}
        ></button>
        <Button
          variant="dark"
          className="mb-3 btn-add states-container states-container-responsive"
        >
          <img src={flag} alt="Flag" className="flag-img me-2" />
          US States
        </Button>
        <Accordion className="states-container">
          <Card className="bg-dark my-2 accordion-menu">
            <Card.Header>
              <CustomToggle
                eventKey="0"
                onClick={() => handleToggle("0")}
                isExpanded={expandedKey === "0"}
              >
                Alabama
              </CustomToggle>
            </Card.Header>
            <Accordion.Collapse eventKey="0" in={expandedKey === "0"}>
              <Card.Body>
                <ul className="list-unstyled category-list text-light">
                  <li>Auburn</li>
                  <li>Birmingham</li>
                  <li>Decatur</li>
                  <li>Dothan</li>
                  <li>Hoover</li>
                </ul>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
          <Card className="bg-dark my-2 accordion-menu">
            <Card.Header>
              <CustomToggle
                eventKey="1"
                onClick={() => handleToggle("1")}
                isExpanded={expandedKey === "1"}
              >
                Alaska
              </CustomToggle>
            </Card.Header>
            <Accordion.Collapse eventKey="1" in={expandedKey === "1"}>
              <Card.Body>
                <ul className="list-unstyled category-list text-light">
                  <li>Auburn</li>
                  <li>Birmingham</li>
                  <li>Decatur</li>
                  <li>Dothan</li>
                  <li>Hoover</li>
                </ul>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
          <Card className="bg-dark my-2 accordion-menu">
            <Card.Header>
              <CustomToggle
                eventKey="2"
                onClick={() => handleToggle("2")}
                isExpanded={expandedKey === "2"}
              >
                Arizona
              </CustomToggle>
            </Card.Header>
            <Accordion.Collapse eventKey="2" in={expandedKey === "2"}>
              <Card.Body>
                <ul className="list-unstyled category-list text-light">
                  <li>Auburn</li>
                  <li>Birmingham</li>
                  <li>Decatur</li>
                  <li>Dothan</li>
                  <li>Hoover</li>
                </ul>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
      </div>
    </div>
  );
};

export { RightSidebar };

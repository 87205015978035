import React, { useState } from "react";
import {
  Container,
  Form,
  Button,
  InputGroup,
  FormControl,
  Spinner,
} from "react-bootstrap";
import { Formik, Field, Form as FormikForm } from "formik";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import validationSchema from "./RegistrationValidation";
import "assets/scss/page/_auth.scss";
import header_logo from "assets/images/header_logo.png";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { useLoginUser, useRegisterUser } from "hooks";
import { loginSuccess, setToken } from "store/features/authSlice";
import { GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { auth, provider } from "../../firebase/firebase";
import { setAuthToken } from "libs";

const initialValues = {
  email: "",
  password: "",
  name: "",
  confirmPassword: "",
  gmail_id: "",
  is_gmail_email_verify: 2,
  signup_type: 1,
};

const Registration = ({ t }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  let userFullName = "";
  // Toggle password visibility
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const togglePasswordVisibilityConfirm = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };
  const handleGoggleRegistration = async () => {
    try {
      await signInWithPopup(auth, provider)
        .then((res) => {
          if (res?._tokenResponse) {
            const { fullName, email, federatedId, photoUrl } =
              res._tokenResponse;
            userFullName = fullName;
            const urlArray = federatedId.split("/");
            const registrationData = {
              name: fullName,
              email: email,
              password: "", // Google login doesn't require password
              gmail_id: urlArray[urlArray.length - 1],
              is_gmail_email_verify: 1,
              signup_type: 2,
              profile_image: photoUrl,
            };
            doLogin(registrationData);
            // Call the registration API using doRegistration function
            // doRegistration(registrationData);
          }
        })
        .catch((error) => {
          console.log("here handleOnClickSocial " + error);
        });
    } catch (error) {
      console.error("Error signing in:", error);
    }
  };
  const { mutate: doRegistration, isLoading } = useRegisterUser((res) => {
    if (res.data) {
      dispatch(setToken({ encoded_token: res.data.encoded_token }));
      if (res.data.user_data.signup_type == 2) {
        navigate("/dashboard");
      } else {
        navigate("/otp-verification");
        toast.success(res.message);
      }
    }
  });

  const { mutate: doLogin } = useLoginUser((res) => {
    if (res.data.encoded_token) {
      dispatch(setToken({ encoded_token: res.data.encoded_token }));

      navigate("/otp-verification");
      toast.error(
        "Email not verified please check your email inbox and verify your email"
      );
    } else {
      if (res.data.user_data.signup_type == 2) {
        const data = {
          name: userFullName,
          email: res.data.user_data.email,
          password: "",
          gmail_id: res.data.user_data.gmail_id,
          is_gmail_email_verify: 1,
          signup_type: res.data.user_data.signup_type,
        };
        doRegistration(data);
      }
      const dataStore = {
        userData: res.data.user_data,
        isLoggedIn: true,
        accessToken: res.data.access_token,
      };
      dispatch(loginSuccess(dataStore));
      setTimeout(function () {
        setAuthToken(res.data.access_token);
        navigate("/dashboard");
      }, 1000);
      toast.success(res.message);
    }
  });

  return (
    <Container className="d-flex justify-content-center align-items-center mt-4 login-container">
      <div className="login-content">
        <Link to={"/"} className="pointer-event">
          <img src={header_logo} alt="Home logo" className="home-logo" />
        </Link>

        <div className="my-3">
          <h3>{t("front_page.register_heading_txt")}</h3>
          <p>{t("front_page.register_create_account_txt")}</p>
        </div>
        <div>
          <Button
            onClick={handleGoggleRegistration}
            variant="transparent"
            className="text-light  w-100 gmail-login"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              x="0px"
              y="0px"
              width="25"
              height="25"
              viewBox="0 0 48 48"
            >
              <path
                fill="#4caf50"
                d="M45,16.2l-5,2.75l-5,4.75L35,40h7c1.657,0,3-1.343,3-3V16.2z"
              ></path>
              <path
                fill="#1e88e5"
                d="M3,16.2l3.614,1.71L13,23.7V40H6c-1.657,0-3-1.343-3-3V16.2z"
              ></path>
              <polygon
                fill="#e53935"
                points="35,11.2 24,19.45 13,11.2 12,17 13,23.7 24,31.95 35,23.7 36,17"
              ></polygon>
              <path
                fill="#c62828"
                d="M3,12.298V16.2l10,7.5V11.2L9.876,8.859C9.132,8.301,8.228,8,7.298,8h0C4.924,8,3,9.924,3,12.298z"
              ></path>
              <path
                fill="#fbc02d"
                d="M45,12.298V16.2l-10,7.5V11.2l3.124-2.341C38.868,8.301,39.772,8,40.702,8h0 C43.076,8,45,9.924,45,12.298z"
              ></path>
            </svg>{" "}
            {t("front_page.sign_in_with_gmail_txt")}
          </Button>
          <div className="separator my-3">
            <span>OR</span>
          </div>
        </div>
        <div style={{ width: "100%", maxWidth: "400px" }}>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={async (values) => {
              values.signup_type = 1;
              doRegistration(values);
              //   navigate("/otp-verification");
            }}
          >
            {({ errors, touched }) => (
              <FormikForm className="login-form" noValidate>
                <Field type="hidden" name="gmail_id" />
                <Form.Group className="mb-3">
                  <Form.Label>
                    {" "}
                    {t("front_page.register_input_name_label")}
                  </Form.Label>
                  <Field
                    as={Form.Control}
                    type="text"
                    name="name"
                    autofill="false"
                    placeholder={t(
                      "front_page.register_input_name_placeholder"
                    )}
                    autoComplete="off"
                    isInvalid={!!errors.name && touched.name}
                  />
                  <Form.Control.Feedback type="invalid">
                    {t(errors.name)}
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>
                    {t("front_page.register_input_email_label")}
                  </Form.Label>
                  <Field
                    as={Form.Control}
                    type="email"
                    name="email"
                    placeholder={t(
                      "front_page.register_input_email_placeholder"
                    )}
                    autoComplete="off"
                    isInvalid={!!errors.email && touched.email}
                  />
                  <Form.Control.Feedback type="invalid">
                    {t(errors.email)}
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>
                    {t("front_page.register_input_password_label")}
                  </Form.Label>
                  <InputGroup>
                    <Field
                      as={FormControl}
                      type={showPassword ? "text" : "password"}
                      name="password"
                      placeholder={t(
                        "front_page.register_input_password_placeholder"
                      )}
                      className="login-password w-100"
                      autoComplete="off"
                      isInvalid={!!errors.password && touched.password}
                    />
                    <span className="password-icon">
                      <InputGroup.Text
                        onClick={togglePasswordVisibility}
                        style={{ cursor: "pointer" }}
                      >
                        {showPassword ? <FaEye /> : <FaEyeSlash />}
                      </InputGroup.Text>
                    </span>
                    <Form.Control.Feedback type="invalid">
                      {t(errors.password)}
                    </Form.Control.Feedback>
                  </InputGroup>
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>
                    {t("front_page.register_input_confirm_password_label")}
                  </Form.Label>
                  <InputGroup>
                    <Field
                      as={FormControl}
                      type={showConfirmPassword ? "text" : "password"}
                      name="confirmPassword"
                      placeholder={t(
                        "front_page.register_input_confirm_password_placeholder"
                      )}
                      className="login-password"
                      autoComplete="off"
                      isInvalid={
                        !!errors.confirmPassword && touched.confirmPassword
                      }
                    />
                    <span className="password-icon">
                      <InputGroup.Text
                        onClick={togglePasswordVisibilityConfirm}
                        style={{ cursor: "pointer" }}
                      >
                        {showConfirmPassword ? <FaEye /> : <FaEyeSlash />}
                      </InputGroup.Text>
                    </span>
                    <Form.Control.Feedback type="invalid">
                      {t(errors.confirmPassword)}
                    </Form.Control.Feedback>
                  </InputGroup>
                </Form.Group>
                <div className="text-center mt-4">
                  <span className="mb-1">
                    {t("front_page.register_all_details_safe")}
                  </span>

                  <p className="m-0">{t("front_page.register_acceptance")}</p>
                  <p>
                    {t("front_page.app_name")}{" "}
                    <Link to={"/page/terms-conditions"} className="hover-txt">
                      {t("front_page.register_term_conditions")}
                    </Link>{" "}
                    and{" "}
                    <Link to={"/page/privacy-policy"} className="hover-txt">
                      {t("front_page.register_privacy_policy")}
                    </Link>
                  </p>
                </div>

                <Button
                  type="submit"
                  variant="primary"
                  className="w-100 my-3 register-btn"
                  disabled={isLoading}
                >
                  {isLoading ? (
                    <>
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                        className="me-2"
                      />
                      {t("front_page.register_loading_spinner_txt")}
                    </>
                  ) : (
                    `${t("front_page.register_sign_up_btn_txt")}`
                  )}
                </Button>
                <div className="text-center mb-4">
                  <span>
                    {t("front_page.register_already_have_account_txt")}{" "}
                    <Link to={"/login"} className="hover-txt">
                      {t("front_page.register_sign_in_btn_txt")}
                    </Link>
                  </span>
                </div>
              </FormikForm>
            )}
          </Formik>
        </div>
      </div>
    </Container>
  );
};

export default Registration;

import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyBel0EnyizPNRWZFly_CN1pmpKX0SUrkJU",
  authDomain: "collegely-c373f.firebaseapp.com",
  projectId: "collegely-c373f",
  storageBucket: "collegely-c373f.appspot.com",
  messagingSenderId: "142384815598",
  appId: "1:142384815598:web:62366f84f6ce292df202ff",
  measurementId: "G-MQ98DY1E5Y",
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const provider = new GoogleAuthProvider();

export { auth, provider };

import React, { useEffect, useState, useTransition } from "react";
import { Button, Nav } from "react-bootstrap";
import { Formik, Form, Field } from "formik";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare, faSearch } from "@fortawesome/free-solid-svg-icons";
import "bootstrap/dist/css/bootstrap.min.css";
import header_logo from "assets/images/header_logo.png";
import { useGetSettingData } from "hooks";
import { useTranslation } from "react-i18next";
import { useSearch } from "context/SearchContext";
// Sidebar Component with Formik
const Sidebar = () => {
  const { t } = useTranslation();
  const [currentView, setCurrentView] = useState("desktop");
  const [isMobile, setIsMobile] = useState(false);
  const [tabView, setTabView] = useState(false);
  const { setSearchTerm } = useSearch();

  useEffect(() => {
    // Function to handle media query changes
    const handleMediaChange = () => {
      const isMobile = window.matchMedia("(max-width: 575px)").matches;
      const isTablet = window.matchMedia(
        "(min-width: 576px) and (max-width: 991px)"
      ).matches;

      if (isMobile) {
        setIsMobile(true);
        setCurrentView("phone");
        setTabView(false);
      } else if (isTablet) {
        setIsMobile(false);
        setCurrentView("tablet");
        setTabView(true);
      } else {
        setIsMobile(false);
        setCurrentView("desktop");
        setTabView(false);
      }
    };

    // Initial check
    handleMediaChange();

    // Add event listeners for media queries
    window.addEventListener("resize", handleMediaChange);

    // Cleanup event listeners on component unmount
    return () => window.removeEventListener("resize", handleMediaChange);
  }, []);

  const { data: getSettingData } = useGetSettingData(
    "/about",
    () => {},
    (error) => {
      if (error.data.redirect == true) {
        console.log("Error occurred: ", error.message);
      }
    }
  );
  const handleCanvasButtonClick = () => {
    // // Get the collection of elements with the class name "offcanvas"
    const elements = document.getElementsByClassName("offcanvas");

    // Check if there are any elements with this class
    if (elements.length > 0) {
      // Access the first element in the collection
      const element = elements[0];

      // Remove the "show" class from the first element
      element.classList.remove("show");
      const toggle = document.getElementsByClassName("left-toggle");
      toggle[0].click();
    } else {
      console.log("No elements found with the class 'offcanvas'.");
    }
  };
  const [search, setSearch] = useState("");
  const [isFocused, setIsFocused] = useState(false);
  const handleSearch = (e) => {
    setSearch(e.target.value);
    setSearchTerm(e.target.value);
  };
  return (
    <div className="sidebar bg-dark text-white position-relative p-3">
      <button
        type="button"
        class="btn-close canvas-btn"
        aria-label="Close"
        onClick={handleCanvasButtonClick}
      ></button>
      <div className="sidebar-top mb-4">
        <h2 className="text-center mb-4">
          <Link
            to="/"
            className="text-white text-decoration-none"
            onClick={handleCanvasButtonClick}
          >
            <img
              src={
                getSettingData?.data.home_page_general_home_page_logo ||
                header_logo
              }
              alt="Header logo"
              className="home-logo"
            />
          </Link>
        </h2>
        <div className="post-add-btn mb-3">
          <Button variant="dark" className="mb-3 w-100 btn-add">
            <FontAwesomeIcon icon={faPenToSquare} className="me-1" />
            {t("front_page.sidebar_post_add_btn_txt")}
          </Button>
        </div>
        {currentView === "desktop" && (
          <>
            <div className="d-flex">
              <div className="input-group">
                <input
                  type="text"
                  id="search"
                  name="search"
                  placeholder={
                    isFocused ? "" : t("front_page.sidebar_search_placeholder")
                  }
                  className="form-control text-light"
                  onChange={(e) => handleSearch(e)}
                  onFocus={() => setIsFocused(true)}
                  onBlur={() => setIsFocused(false)}
                />
                {search === "" && (
                  <FontAwesomeIcon
                    icon={faSearch}
                    className="search-icon position-absolute"
                  />
                )}
              </div>
            </div>
          </>
        )}
      </div>

      <div className="sidebar-bottom">
        {/* Navigation Links */}
        <Nav className="flex-column list-unstyled link-lists">
          <Link
            to={"/about"}
            className="text-white nav-link-custom"
            onClick={handleCanvasButtonClick}
          >
            {t("front_page.sidebar_link_about")}
          </Link>
          <Link
            to={"/contact"}
            className="text-white nav-link-custom"
            onClick={handleCanvasButtonClick}
          >
            {t("front_page.sidebar_link_contact_us")}
          </Link>
          <Link
            to={"/faq"}
            className="text-white nav-link-custom"
            onClick={handleCanvasButtonClick}
          >
            {t("front_page.sidebar_link_help")}
          </Link>
          <Link
            to={"/page/privacy-policy"}
            className="text-white nav-link-custom"
            onClick={handleCanvasButtonClick}
          >
            {t("front_page.sidebar_link_privacy")}
          </Link>
          <Link
            to={"/page/terms-conditions"}
            className="text-white nav-link-custom last-row"
            onClick={handleCanvasButtonClick}
          >
            {t("front_page.sidebar_link_term")}
          </Link>
        </Nav>
      </div>
    </div>
  );
};

export { Sidebar };

import React, { useState } from "react";
import { Formik, Field, Form as FormikForm } from "formik";
import * as Yup from "yup";
import { Button, Form, Container, Modal } from "react-bootstrap";
import { NavItemsCommon } from "common/components";
import { EditEmailNavItems } from "config/navConfig";
import { toast } from "react-toastify";
import "assets/scss/page/_edit_profile.scss";
import { useDeactivateAccount } from "hooks";
import { ReactComponent as DeactivateIcon } from "assets/images/deactivate-icon.svg";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { logoutSuccess } from "store/features/authSlice";

// Validation schema for the form
const validationSchema = Yup.object({
  reason: Yup.string()
    .required("front_page.deactivate_account_reason_required")
    .min(3, "front_page.deactivate_account_reason_min_length"),
});

const DeactivateAccount = ({ t }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [formData, setFormData] = useState(null);
  const { mutate: doDeactivateAccount, isLoading } = useDeactivateAccount(
    (res) => {
      if (res.data) {
        toast.success(res.message);

        dispatch(logoutSuccess());
        navigate("/login");

        // Handle navigation or other post-deactivation actions here
      }
    }
  );

  const handleSubmit = (values, { setSubmitting }) => {
    setFormData(values); // Save form data to trigger deactivation on confirmation
    setShowModal(true); // Show confirmation modal
    setSubmitting(false);
  };

  const handleDeactivate = () => {
    // Perform deactivation once user confirms
    if (formData) {
      doDeactivateAccount({ reason: formData.reason });
      setShowModal(false);
    }
  };

  return (
    <div>
      <h2>{t("front_page.my_profile_label")}</h2>
      <NavItemsCommon navItemName={EditEmailNavItems} t={t} />
      <div className=" mt-5">
        <div className="d-flex justify-content-center">
          <DeactivateIcon />
        </div>
        <div className="d-flex justify-content-center">
          <Formik
            initialValues={{ reason: "" }}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ errors, touched, isSubmitting }) => (
              <FormikForm className="mt-4 deactivate-form">
                <Form.Group className="mb-3">
                  <Form.Label>
                    {t("front_page.deactivate_account_reason_label")}
                  </Form.Label>
                  <Field
                    as="textarea"
                    name="reason"
                    className={`form-control bg-dark text-light ${
                      touched.reason && errors.reason ? "is-invalid" : ""
                    }`}
                    placeholder={t(
                      "front_page.deactivate_account_reason_placeholder"
                    )}
                    rows={4}
                  />
                  {touched.reason && errors.reason && (
                    <div className="invalid-feedback">{t(errors.reason)}</div>
                  )}
                </Form.Group>

                <Button
                  type="submit"
                  className="w-100"
                  disabled={isSubmitting || isLoading}
                >
                  {isLoading
                    ? t("front_page.deactivate_account_loading")
                    : t("front_page.deactivate_account_button_label")}
                </Button>
              </FormikForm>
            )}
          </Formik>
        </div>
      </div>

      {/* Modal for Confirmation */}
      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        centered
        className="deactivate-modal"
      >
        <Modal.Header
          closeButton
          className="d-flex flex-column-reverse border-0"
        >
          <Modal.Title className="mt-4 w-100">
            <div className="d-flex justify-content-center">
              <DeactivateIcon />
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="fs-2 border-0 text-center">
          <div>{t("front_page.deactivate_account_confirmation")}</div>
        </Modal.Body>
        <Modal.Footer className="border-0">
          <Button variant="dark" onClick={() => setShowModal(false)}>
            {t("front_page.cancel_button_label")}
          </Button>
          <Button variant="danger" onClick={handleDeactivate}>
            {t("front_page.deactivate_account_confirm_button_label")}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default DeactivateAccount;

import { PaymentService } from "api";
import { useMutation, useQuery } from "react-query";
import { toast } from "react-toastify";
const onDefaultError = (error) => {
  toast.error(error.message);
};

const useAddStripeCard = (onSuccess, onError = onDefaultError) => {
  return useMutation(PaymentService.addCard, {
    onSuccess,
    onError,
  });
};
const useGetStripeCards = (onSuccess, onError = onDefaultError) => {
  return useQuery("get-stripe-cards", PaymentService.getCards, {
    onSuccess,
    onError,
  });
};
const useDeleteStripeCards = (onSuccess, onError = onDefaultError) => {
  return useMutation(PaymentService.deleteCard, {
    onSuccess,
    onError,
  });
};
export { useAddStripeCard, useGetStripeCards, useDeleteStripeCards };
